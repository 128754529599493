import React, { useState, useEffect } from "react";
import videoBannerLargeAndroid from "./images/LogoDiaCas.mp4";
import videoBannerShortAndrod from "./images/380X573.mp4";
import videoBannerShortIos from "./images/loGO_DORADO_3.gif";

export function Banner() {
  // Corregido el uso de useState
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    setIsIos(/iPhone|iPad|iPod/i.test(navigator.userAgent));
  }, []);

  return (
    <div className="banner">
      {isIos ? (
        <div className="banner__video">
          <img
            src={window.innerWidth > 867 ? videoBannerLargeAndroid : videoBannerShortIos}
            alt=""
            className="banner__video-gif"
          />
        </div>
      ) : (
        <video className="banner__video" muted autoPlay loop>
          <source
            src={window.innerWidth > 867 ? videoBannerLargeAndroid : videoBannerShortAndrod}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
}
