import './App.css';
import { Banner} from './Banner';
import { Promotions } from './Promotions';
import { Footer } from './Footer';
function App() {
  return (
    <div className="App">
      <Banner></Banner>
      <Promotions></Promotions>
      <Footer></Footer>
     
    </div>
  );
}

export default App;
