

export function Footer() {

  return (
    <div className="footer">
        <div className="footer__container">
            <h3 className="footer__text">Promociones Diamante Casino</h3>
            <h3 className="footer__text">© 2024. All Rights Reserved.</h3>
        </div>

    </div>
  );
}
