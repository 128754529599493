
import promotionImage1 from "./images/Evento_POST-2 (1).jpg"


export function Promotions() {

  return (
    <div className="promotions">
        <div className="promotions__title-container">
            <h1 className="promotions__title">PROMOCIONES VIGENTES</h1>
        </div>
        <div className="promotions__container">
            <div className="promotions__images-container">
                <img src={promotionImage1} alt="" className="promotion__image" />
                <img src={promotionImage1} alt="" className="promotion__image" />
                <img src={promotionImage1} alt="" className="promotion__image" />
                <img src={promotionImage1} alt="" className="promotion__image" />
                <img src={promotionImage1} alt="" className="promotion__image" />
                <img src={promotionImage1} alt="" className="promotion__image" />
                <img src={promotionImage1} alt="" className="promotion__image" />
                <img src={promotionImage1} alt="" className="promotion__image" />
            </div>
        </div>


     
    </div>
  );
}
